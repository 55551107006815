import React from 'react';
import './Logo.scss';

const Logo = () => (
  <span className="logo">
    <span className="d-bg"></span>
    <span className="d-character">D</span>
  </span>
)

export default Logo;
