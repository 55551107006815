import React from 'react';
import Seo from './Seo/Seo';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

type LayoutProps = {
  children?: React.ReactNode;
  metaTags: any;
  location: any;
};

const Layout = ({ children, metaTags, location }: LayoutProps) => (
  <div className="layout-container">
    <Seo metaTags={metaTags} />
    <Navbar location={ location }/>
    <main className="page-content" aria-label="Content">
      <div className="container padder-v">
        {children}
      </div>
    </main>
    <Footer />
  </div>
);

export default Layout;
